<template>
  <div>
    <UiModal v-model="open">
      <div class="flex flex-col gap-2 w-full max-w-[400px]">
        <h3 class="text-xl font-serif text-gray-600">
          {{ title }}
        </h3>
        <p class="text-lg text-gray-600">
          {{ description }}
        </p>

        <div class="flex justify-end gap-3 mt-2">
          <UiButton size="sm" variant="flat" bg-color="white" @click="open = false" :disabled="loading">
            {{ btnCancel }}
          </UiButton>
          <UiButton :bg-color="btnColor" size="sm" @click="executeAction" :loading="loading">
            {{ btnConfirm }}
          </UiButton>
        </div>
      </div>
    </UiModal>
    <div @click.stop="disabled ? null : open = true">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { UiModal, UiButton } from '.';

type ConfirmProps = {
  title?: string
  description?: string
  btnCancel?: string
  btnConfirm?: string
  loading?: boolean
  disabled?: boolean
  btnColor?: 'default' | 'secondary' | 'secondaryLight' | 'white' | 'danger' | 'dangerUp'
  action: () => Promise<void> | void
}

const props = withDefaults(defineProps<ConfirmProps>(), {
  title: 'Tem certeza?',
  description: 'Essa ação é irreversível',
  btnCancel: 'Cancelar',
  btnConfirm: 'Confirmar',
  loading: false,
  btnColor: 'default'
})

const open = ref(false)

const executeAction = async () => {
  await props.action()
  open.value = false
}
</script>
